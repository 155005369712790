export const mainData = {
    namespaced: true,
    state: () => ({
        hasCdnMenu: false,  // 是否包含监课菜单
        hasRecordMenu: false, // 是否包含回放管理菜单
    }),
    mutations: {
        hasCdnMenuChange(state, val){
            state.hasCdnMenu = val
        },
        hasRecordMenuChange(state, val){
            state.hasRecordMenu = val
        }
    },
}