import Vue from 'vue'
import Router from 'vue-router'
import login from '@/page/login'
import index from '@/page/index'
import store from '@/store'
/* 系统 -> 系统管理 */
import areaManage from '@/page/system/systemManage/areaManage'
import courseManage from '@/page/system/systemManage/courseManage'
import macroManage from '@/page/system/systemManage/macroManage'
import orgManage from '@/page/system/systemManage/orgManage'
import roleManage from '@/page/system/systemManage/roleManage'
import menuManage from '@/page/system/systemManage/menuManage'
import userManage from '@/page/system/systemManage/userManage'
import flowFeeManage from '@/page/system/systemManage/flowFeeManage'
import orgChargeManage from '@/page/system/systemManage/orgChargeManage'
import orgChargeDetail from '@/page/system/systemManage/orgChargeDetail'
/* 系统 -> APP管理 */
import articleManage from '@/page/system/appManage/articleManage'
import bannerManage from '@/page/system/appManage/bannerManage'
import informationManage from '@/page/system/appManage/informationManage'
import inlineManage from '@/page/system/appManage/inlineManage'
import tagsManage from '@/page/system/appManage/tagsManage'
import teacherCashManage from '@/page/system/appManage/teacherCashManage'
/* 系统 -> 公共资源库 */
import sourceManage from '@/page/system/publicSource/sourceManage'

/* 销售部分 */
/* 部门管理 */
import teamSetting from '@/page/sales/sectionManage/teamSetting'
import customerManage from '@/page/sales/sectionManage/customerManage'
import releaseCustomer from '@/page/sales/sectionManage/releaseCustomer'
import allocated from '@/page/sales/sectionManage/allocated'
/* 我的订单 */
import myOrder from '@/page/sales/myOrder/myOrder'
/* 我的客户 */
import myCustomer from '@/page/sales/myCustomer/myCustomer'
import studentInfo from '@/page/sales/myCustomer/studentInfo'
import sectionManageStudentInfo from '@/page/sales/sectionManage/studentInfo'
import blackArea from '@/page/sales/blackArea'//待黑区
import blackList from '@/page/sales/blackList'//黑名单客户
import registered from '@/page/sales/registered'//已注册客户
import customerPool from '@/page/sales/customerPool'//客户管理池
import autoDist from '@/page/sales/autoDist'//自动分配

/* 财务 */
import orderCheck from '@/page/finance/orderCheck'
import personManage from '@/page/finance/personManage'
import productIncome from '@/page/finance/productIncome'
import refundDetails from '@/page/finance/refundDetails'
import withdrawalToExamine from '@/page/finance/withdrawalToExamine'
import classHours from '@/page/finance/classHours'
import classPay from '@/page/finance/classPay'//课酬设置
import unitPrice from '@/page/finance/unitPrice'//单价设置
import organFeeDetail from '@/page/finance/organFeeDetail'
/* 运营 */
import oneToOne from '@/page/operates/productManage/oneToOne'
import oneToMore from '@/page/operates/productManage/oneToMore'
import oneToAll from '@/page/operates/productManage/oneToAll'

// 讲师
import teacherManage from '@/page/teacher/teacherManage/teacherManageList'
import adviserManage from '@/page/teacher/adviserManage/adviserManageList'
import auditorManage from '@/page/teacher/auditorManage/auditorManageList'
import subjectManage from '@/page/teacher/auditorManage/subjectManageList'
import teacherScheduling from '@/page/teacher/auditorManage/teacherScheduling' //讲师排课量
import knowledgeCatalog from '@/page/teacher/auditorManage/knowledgeCatalog' //知识点目录
import teachingCatalog from '@/page/teacher/auditorManage/teachingCatalog' //教学目录
import knowledgeList from '@/page/teacher/auditorManage/knowledgeList' //知识点列表
import paperList from '@/page/teacher/auditorManage/paperList' //试卷列表
import interviewAnswer from '@/page/teacher/auditorManage/interviewAnswer' //面试答题

//教务
import orderlist from '@/page/teachBusiness/expertTeachBusiness/orderlist' //教务订单
import teacherManageList from '@/page/teachBusiness/expertTeachBusiness/teacherManage' //人员管理
import studentManage from '@/page/teachBusiness/expertTeachBusiness/studentManage' //学员管理
import onToMoreWork from '@/page/teachBusiness/expertTeachBusiness/onToMoreWork' //一对多排课
import timetable from '@/page/teachBusiness/expertTeachBusiness/timetable' //课表管理
import classHour from '@/page/teachBusiness/expertTeachBusiness/classHour' //课表管理
import messageCenter from '@/page/teachBusiness/expertTeachBusiness/chat/messageCenter' //消息中心
import playback from '@/page/teachBusiness/expertTeachBusiness/playback' //回放
import allplayback from '@/page/teachBusiness/tubeTeachBusiness/allplayback' //主管回放
import superviseClass from '@/page/teachBusiness/expertTeachBusiness/superviseClass' //监课
import surplusClass from '@/page/teachBusiness/expertTeachBusiness/surplusClass' //剩余课时

import personnelList from '@/page/teachBusiness/tubeTeachBusiness/personnelList'
import classStudent from '@/page/teachBusiness/tubeTeachBusiness/classStudent'
import onToMoreBranch from '@/page/teachBusiness/tubeTeachBusiness/onToMoreBranch' //一对多分配
import classTimetable from '@/page/teachBusiness/tubeTeachBusiness/classTimetable' //部门课表
import refund from '@/page/teachBusiness/tubeTeachBusiness/refund' //部门课表
import allClassHours from '@/page/teachBusiness/tubeTeachBusiness/allClassHours'
import allsuperviseClass from '@/page/teachBusiness/tubeTeachBusiness/allsuperviseClass' //主管监课
import surplusClassAll from '@/page/teachBusiness/tubeTeachBusiness/surplusClassAll' //主管剩余课时
//班课管理员
import openClass from '@/page/openClass/index' //公开课排课
import supervise from '@/page/openClass/supervise' //监课管理
import back from '@/page/openClass/back' //回放管理
import classManage from '@/page/openClass/classManage' //课时管理
import oneToMany from '@/page/openClass/oneToMany' //一对多管理
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> {})
}
const router = new Router({
    routes: [{
            path: '/',
            name: 'login',
            component: login,
            redirect: '/login',
            meta: {
                title: '登录',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                title: '登录',
                breadList: ['登录'], 
            },
        },
        {
            path: '/index',
            name: 'index',
            component: index,
            meta: {
                title: '首页',
                breadList: [''], 
            },
            children: [
                /* 系统 -> 系统管理路由 */
                {
                    path: '/system/systemManage/areaManage',
                    name: 'areaManage',
                    component: areaManage,
                    meta: {
                        title: '行政区域',
                        breadList: ['系统', '系统管理', '行政区域'],
                    },
                },
                {
                    path: '/system/systemManage/courseManage',
                    name: 'courseManage',
                    component: courseManage,
                    meta: {
                        title: '课程体系',
                        breadList: ['系统', '系统管理', '课程体系'],
                    },
                },
                {
                    path: '/system/systemManage/macroManage',
                    name: 'macroManage',
                    component: macroManage,
                    meta: {
                        title: '通用字典',
                        breadList: ['系统', '系统管理', '通用字典'],
                    },
                },
                {
                    path: '/system/systemManage/orgManage',
                    name: 'orgManage',
                    component: orgManage,
                    meta: {
                        title: '机构管理',
                        breadList: ['系统', '系统管理', '机构管理'],
                    },
                },
                {
                    path: '/system/systemManage/roleManage',
                    name: 'roleManage',
                    component: roleManage,
                    meta: {
                        title: '角色管理',
                        breadList: ['系统', '系统管理', '角色管理'],
                    },
                },
                {
                    path: '/system/systemManage/menuManage',
                    name: 'menuManage',
                    component: menuManage,
                    meta: {
                        title: '系统菜单',
                        breadList: ['系统', '系统管理', '系统菜单'],
                    },
                },
                {
                    path: '/system/systemManage/userManage',
                    name: 'userManage',
                    component: userManage,
                    meta: {
                        title: '用户管理',
                        breadList: ['系统', '系统管理', '用户管理'],
                    },
                },
                {
                    path: '/system/systemManage/flowFeeManage',
                    name: 'flowFeeManage',
                    component: flowFeeManage,
                    meta: {
                        title: '流量费设置',
                        breadList: ['系统', '系统管理', '流量费设置'],
                    },
                },
                {
                    path: '/system/systemManage/orgChargeManage',
                    name: 'orgChargeManage',
                    component: orgChargeManage,
                    meta: {
                        title: '机构费用',
                        breadList: ['系统', '系统管理', '机构费用'],
                    },
                },
                /* 不在导航中 */
                {
                    path: '/system/systemManage/orgChargeDetail',
                    name: 'orgChargeDetail',
                    component: orgChargeDetail,
                    meta: {
                        title: '机构费用明细',
                        breadList: ['系统', '系统管理', '机构费用', '机构费用明细'],
                    },
                },
                /* 系统 -> app管理路由 */
                {
                    path: '/system/appManage/articleManage',
                    name: 'articleManage',
                    component: articleManage,
                    meta: {
                        title: '好文推荐',
                        breadList: ['系统', '资讯管理', '好文推荐'],
                    },
                },
                {
                    path: '/system/appManage/bannerManage',
                    name: 'bannerManage',
                    component: bannerManage,
                    meta: {
                        title: 'Banner 管理',
                        breadList: ['系统', '资讯管理', 'Banner 管理'],
                    },
                },
                {
                    path: '/system/appManage/informationManage',
                    name: 'informationManage',
                    component: informationManage,
                    meta: {
                        title: '资讯列表',
                        breadList: ['系统', '资讯管理', '资讯列表'],
                    },
                },
                {
                    path: '/system/appManage/inlineManage',
                    name: 'inlineManage',
                    component: inlineManage,
                    meta: {
                        title: '内链管理列表',
                        breadList: ['系统', '资讯管理', '内链管理列表'],
                    },
                },
                {
                    path: '/system/appManage/tagsManage',
                    name: 'tagsManage',
                    component: tagsManage,
                    meta: {
                        title: 'tags标签管理',
                        breadList: ['系统', '资讯管理', 'tags标签管理'],
                    },
                },
                {
                    path: '/system/appManage/teacherCashManage',
                    name: 'teacherCashManage',
                    component: teacherCashManage,
                    meta: {
                        title: '教师提现配置',
                        breadList: ['系统', '资讯管理', '教师提现配置'],
                    },
                },
                /* 系统 -> 公共资源库 */
                {
                    path: '/system/publicSource/sourceManage',
                    name: 'sourceManage',
                    component: sourceManage,
                    meta: {
                        title: '公共资源库',
                        breadList: ['系统', '公共资源库'],
                    },
                },
                /* 销售 */
                {
                    path: '/sales/sectionManage/teamSetting',
                    name: 'teamSetting',
                    component: teamSetting,
                    meta: {
                        title: '团队设置',
                        breadList: ['销售', '部门管理', '团队设置'],
                    },
                },
                {
                    path: '/sales/sectionManage/customerManage',
                    name: 'customerManage',
                    component: customerManage,
                    meta: {
                        title: '客户管理池',
                        breadList: ['销售', '部门管理', '客户管理池'],
                    },
                },
                {
                    path: '/sales/sectionManage/releaseCustomer',
                    name: 'releaseCustomer',
                    component: releaseCustomer,
                    meta: {
                        title: '被释放客户',
                        breadList: ['销售', '部门管理', '被释放客户'],
                    },
                },
                {
                    path: '/sales/myOrder/myOrder',
                    name: 'myOrder',
                    component: myOrder,
                    meta: {
                        title: '我的订单',
                        breadList: ['销售', '我的订单', '我的订单'],
                    },
                },
               
                /* 我的客户 */
                {
                    path: '/sales/myCustomer/myCustomer',
                    name: 'myCustomer',
                    component: myCustomer,
                    meta: {
                        title: '我的客户',
                        breadList: ['销售', '我的客户', '我的客户'],
                    },
                },
                {
                    path: '/sales/myCustomer/myCustomer/studentInfo/:id',
                    name: 'myCustomer_studentInfo',
                    component: studentInfo,
                    meta: {
                        title: '客户详情',
                        breadList: ['销售', '我的客户', '我的客户', '客户详情'],
                    },
                },
                {
                    path: '/sales/sectionManage/sectionManage/studentInfo/:id',
                    name: 'sectionManageStudentInfo',
                    component: sectionManageStudentInfo,
                    meta: {
                        title: '客户详情',
                        breadList: ['销售', '部门管理', '客户详情'],
                    },
                },
                {
                    path: '/sales/blackArea',
                    name: 'blackArea',
                    component: blackArea,
                    meta: {
                        title: '待黑区',
                        breadList: ['销售', '数据管理', '待黑区'],
                    },
                },
                {
                    path: '/sales/blackList',
                    name: 'blackList',
                    component: blackList,
                    meta: {
                        title: '黑名单客户',
                        breadList: ['销售', '数据管理', '黑名单客户'],
                    },
                },
                {
                    path: '/sales/registered',
                    name: 'registered',
                    component: registered,
                    meta: {
                        title: '已注册客户',
                        breadList: ['销售', '数据管理', '已注册客户'],
                    },
                },
                {
                    path: '/sales/customerPool',
                    name: 'customerPool',
                    component: customerPool,
                    meta: {
                        title: '客户管理池',
                        breadList: ['销售', '数据管理', '客户管理池'],
                    },
                },
                {
                    path: '/sales/autoDist',
                    name: 'autoDist',
                    component: autoDist,
                    meta: {
                        title: '自动分配',
                        breadList: ['销售', '数据管理', '自动分配'],
                    },
                },
                /* 运营 */
                {
                    path: '/operates/productManage/oneToOne',
                    name: 'oneToOne',
                    component: oneToOne,
                    meta: {
                        title: '试听课',
                        breadList: ['运营', '试听课'],
                    },
                },
                {
                    path: '/operates/productManage/oneToMore',
                    name: 'oneToMore',
                    component: oneToMore,
                    meta: {
                        title: '一对多',
                        breadList: ['运营', '一对多'],
                    },
                },
                {
                    path: '/operates/productManage/oneToAll',
                    name: 'oneToAll',
                    component: oneToAll,
                    meta: {
                        title: '免费公开课',
                        breadList: ['运营', '免费公开课'],
                    },
                },
                /* 财务 */
                {
                    path: '/finance/orderCheck',
                    name: 'orderCheck',
                    component: orderCheck,
                    meta: {
                        title: '订单审核',
                        breadList: ['财务', '订单审核'],
                    },
                },
                {
                    path: '/finance/personManage',
                    name: 'personManage',
                    component: personManage,
                    meta: {
                        title: '人员管理',
                        breadList: ['财务', '人员管理'],
                    },
                },
                
                /* 财务中的剩余课时 */
                {
                    path: '/finance/tubeTeachBusiness/surplusClassAll',
                    name: 'surplusClassAll',
                    component: surplusClassAll,
                    meta: {
                        title: '剩余课时',
                        breadList: ['财务', '剩余课时'],
                    },
                },
                {
                    path: '/finance/productIncome',
                    name: 'productIncome',
                    component: productIncome,
                    meta: {
                        title: '产品收入',
                        breadList: ['财务', '产品收入'],
                    },
                },
                {
                    path: '/finance/withdrawalToExamine',
                    name: 'withdrawalToExamine',
                    component: withdrawalToExamine,
                    meta: {
                        title: '提现待审核',
                        breadList: ['财务', '提现待审核'],
                    },
                },
                {
                    path: '/finance/classPay',
                    name: 'classPay',
                    component: classPay,
                    meta: {
                        title: '课酬设置',
                        breadList: ['财务', '课酬设置'],
                    },
                },
                {
                    path: '/finance/unitPrice',
                    name: 'unitPrice',
                    component: unitPrice,
                    meta: {
                        title: '单价设置',
                        breadList: ['财务', '单价设置'],
                    },
                },
                {
                    path: '/finance/organFeeDetail',
                    name: 'organFeeDetail',
                    component: organFeeDetail,
                    meta: {
                        title: '流量费用',
                        breadList: ['财务', '流量费用'],
                    },
                },
                { 
                    path: '/finance/classHours',
                    name: 'classHours',
                    component: classHours,
                    meta: {
                        title: '课时统计',
                        breadList: ['财务', '课时统计'],
                    },
                },
                {
                    path: '/finance/refundDetails',
                    name: 'refundDetails',
                    component: refundDetails,
                    meta: {
                        title: '退款明细',
                        breadList: ['财务', '退款明细'],
                    },
                },
                // 讲师
                {
                    path: '/teacher/teacherManage/teacherManageList',
                    name: 'teacherManage',
                    component: teacherManage,
                    meta: {
                        title: '讲师列表',
                        breadList: ['讲师', '讲师管理', '讲师列表'],
                    },
                },
                {
                    path: '/teacher/adviserManage/adviserManageList',
                    name: 'adviserManage',
                    component: adviserManage,
                    meta: {
                        title: '人员管理',
                        breadList: ['讲师', '顾问管理', '人员管理'],
                    },
                },
                {
                    path: '/teacher/auditorManage/auditorManageList',
                    name: 'auditorManage',
                    component: auditorManage,
                    meta: {
                        title: '讲师列表',
                        breadList: ['讲师', '讲师审核', '讲师列表'],
                    },
                },
                {
                    path: '/teacher/auditorManage/teacherScheduling',
                    name: 'teacherScheduling',
                    component: teacherScheduling,
                    meta: {
                        title: '教师排课量',
                        breadList: ['讲师', '讲师审核', '教师排课量'],
                    },
                },
                {
                    path: '/teacher/auditorManage/subjectManageList',
                    name: 'subjectManage',
                    component: subjectManage,
                    meta: {
                        title: '试题列表',
                        breadList: ['讲师', '讲师审核', '试题试卷','试题列表'],
                    },
                },
                {
                    path: '/teacher/auditorManage/knowledgeCatalog',
                    name: 'knowledgeCatalog',
                    component: knowledgeCatalog,
                    meta: {
                        title: '知识点目录',
                        breadList: ['讲师', '讲师审核', '试题试卷','知识点目录'],
                    },
                },
                {
                    path: '/teacher/auditorManage/knowledgeList',
                    name: 'knowledgeList',
                    component: knowledgeList,
                    meta: {
                        title: '知识点列表',
                        breadList: ['讲师', '讲师审核', '试题试卷','知识点列表'],
                    },
                },
                {
                    path: '/teacher/auditorManage/teachingCatalog',
                    name: 'teachingCatalog',
                    component: teachingCatalog,
                    meta: {
                        title: '教学目录',
                        breadList: ['讲师', '讲师审核', '试题试卷','教学目录'],
                    },
                },
                {
                    path: '/teacher/auditorManage/paperList',
                    name: 'paperList',
                    component: paperList,
                    meta: {
                        title: '试卷列表',
                        breadList: ['讲师', '讲师审核', '试题试卷','试卷列表'],
                    },
                },
                {
                    path: '/teacher/auditorManage/interviewAnswer',
                    name: 'interviewAnswer',
                    component: interviewAnswer,
                    meta: {
                        title: '试答题',
                        breadList: ['讲师', '讲师审核', '试题试卷','试答题'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/personnelList',
                    name: 'personnelList',
                    component: personnelList,
                    meta: {
                        title: '人员管理',
                        breadList: ['教务', '教务主管', '人员管理'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/allClassHours',
                    name: 'allClassHours',
                    component: allClassHours,
                    meta: {
                        title: '部门课时',
                        breadList: ['教务', '教务主管', '部门课时'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/allsuperviseClass',
                    name: 'allsuperviseClass',
                    component: allsuperviseClass,
                    meta: {
                        title: '监课管理',
                        breadList: ['教务', '教务主管', '监课管理'],
                    },
                },
                /* 教务主管中的剩余课时 */
                {
                    path: '/teachBusiness/tubeTeachBusiness/surplusClassAll',
                    name: 'surplusClassAll',
                    component: surplusClassAll,
                    meta: {
                        title: '剩余课时',
                        breadList: ['教务', '教务主管', '剩余课时'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/orderlist',
                    name: 'orderlist',
                    component: orderlist,
                    meta: {
                        title: '教务订单',
                        breadList: ['教务', '教务专员', '教务订单'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/teacherManage',
                    name: 'teacherManageList',
                    component: teacherManageList,
                    meta: {
                        title: '教师管理',
                        breadList: ['教务', '教务专员', '教师管理'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/classStudent',
                    name: 'classStudent',
                    component: classStudent,
                    meta: {
                        title: '部门学员',
                        breadList: ['教务', '教务主管', '部门学员'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/studentManage',
                    name: 'studentManage',
                    component: studentManage,
                    meta: {
                        title: '学员管理',
                        breadList: ['教务', '教务专员', '学员管理'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/messageCenter',
                    name: 'messageCenter',
                    component: messageCenter,
                    meta: {
                        title: '消息中心',
                        breadList: ['教务', '教务专员', '消息中心'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/playback',
                    name: 'playback',
                    component: playback,
                    meta: {
                        title: '回放管理',
                        breadList: ['教务', '教务专员', '回放管理'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/allplayback',
                    name: 'allplayback',
                    component: allplayback,
                    meta: {
                        title: '回放管理',
                        breadList: ['教务', '教务主管', '回放管理'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/superviseClass',
                    name: 'superviseClass',
                    component: superviseClass,
                    meta: {
                        title: '监课管理',
                        breadList: ['教务', '教务专员', '监课管理'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/surplusClass',
                    name: 'surplusClass',
                    component: surplusClass,
                    meta: {
                        title: '剩余课时',
                        breadList: ['教务', '教务专员', '剩余课时'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/onToMoreBranch',
                    name: 'onToMoreBranch',
                    component: onToMoreBranch
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/onToMoreWork',
                    name: 'onToMoreWork',
                    component: onToMoreWork
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/timetable',
                    name: 'timetable',
                    component: timetable,
                    meta: {
                        title: '课表管理',
                        breadList: ['教务', '教务专员', '课表管理'],
                    },
                },
                {
                    path: '/teachBusiness/expertTeachBusiness/classHour',
                    name: 'classHour',
                    component: classHour,
                    meta: {
                        title: '课时管理',
                        breadList: ['教务', '教务专员', '课时管理'],
                    },
                },
                {
                    path: '/sales/sectionManage/allocated',
                    name: 'allocated',
                    component: allocated,
                    meta: {
                        title: '已分配客户',
                        breadList: ['销售', '部门管理', '已分配客户'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/classTimetable',
                    name: 'classTimetable',
                    component: classTimetable,
                    meta: {
                        title: '部门课表',
                        breadList: ['教务', '教务主管', '部门课表'],
                    },
                },
                {
                    path: '/teachBusiness/tubeTeachBusiness/refund',
                    name: 'refund',
                    component: refund,
                    meta: {
                        title: '退费审核',
                        breadList: ['教务', '教务主管', '退费审核'],
                    },
                },
                {
                    path: '/openClass/index',
                    name: 'openClass',
                    component: openClass,
                    meta: {
                        title: '公开课排课',
                        breadList: ['班课管理员', '公开课排课'],
                    },
                },
                {
                    path: '/openClass/supervise',
                    name: 'supervise',
                    component: supervise,
                    meta: {
                        title: '监课管理',
                        breadList: ['班课管理员', '监课管理'],
                    },
                },
                {
                    path: '/openClass/back',
                    name: 'back',
                    component: back,
                    meta: {
                        title: '回放管理',
                        breadList: ['班课管理员', '回放管理'],
                    },
                },
                {
                    path: '/openClass/classManage',
                    name: 'classManage',
                    component: classManage,
                    meta: {
                        title: '课时管理',
                        breadList: ['班课管理员', '课时管理'],
                    },
                },
                {
                    path: '/openClass/oneToMany',
                    name: 'oneToMany',
                    component: oneToMany,
                    meta: {
                        title: '一对多排课',
                        breadList: ['班课管理员', '一对多排课'],
                    },
                },
            ]
        },
    ]
})
router.beforeEach((to, _, next) => {
    //console.log('router.beforeEach: ', to)
    if(to.path.includes('messageCenter') || to.path.includes('login')){
        store.commit('chatInfo/chatShotShowChange', false)
    }else{
        store.commit('chatInfo/chatShotShowChange', true)
    }
    store.commit('chatInfo/chatBoxShowChange', {
        show: false
    })
    store.commit('chatInfo/chatShotConListShowChange', false)
    store.commit('chatInfo/chatShotConversationShowChange', false)
    next()
})
router.afterEach( to => {
    document.title = to.meta.title
    store.commit('chatInfo/breadListChange', to.meta.breadList)
})
export default router